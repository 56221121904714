.navitem {
  font-weight: 400;
  font-size: 20px;
  color: var(--text-color);
  margin-left: 56px;
  cursor: pointer;
}

.navitem:hover {
  opacity: 0.7;
}
