.time-selector {
}

.time-selector .range {
  margin-top: 16px;
  color: #8f8f8f;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
}

.time-selector .group {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(112px, 1fr));
}
