.form-group {
  border: 1px solid transparent;
  background-color: white;
  border-radius: 10px;
  height: 60px;
  margin-left: 12px;
  padding: 11px 15px 0 16px;
}

.input-element {
  width: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
}

.label-element {
  display: block;
  font-weight: 700;
  font-size: 14px;
}

.input-box-active {
  border: 1px solid blue;
}


@media only screen and (max-width: 820px) {
  .form-group {
    border: 1px solid transparent;
    background-color: white;
    border-radius: 10px;
    height: 65px;
    margin-bottom: 20px;
  }

  .invalid-input {
    border: 1px solid red;
  }
}

@media only screen and (max-width: 560px) {
  .form-group {
    border: 1px solid transparent;
    background-color: white;
    border-radius: 10px;
    height: 65px;
    margin-bottom: 20px;
  }

  .invalid-input {
    border: 1px solid red;
  }
}
