.modal-background {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.5s;

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-container {
  background-color: #ffffff;

  margin: 5%;
  margin-right: 10%;
  border-radius: 32px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1e0e4;
  padding: 30px;
}

.modal-close {
  color: var(--primary-color);
  cursor: pointer;
  transition: color 0.1s ease-in-out;
}

.modal-close:hover {
  color: #03b4ec;
}

.modal-caption {
  text-align: center;
  flex: 1;
}

.modal-body {
  padding: 30px 64px;
  text-align: center;
}
