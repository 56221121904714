.day-selector {
  justify-content: center;

}

.day-selector .week {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

}
.day-selector .days {
  display: flex;
  overflow: auto;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  justify-content: flex-start;
}

.day-selector .week-button {
  color: var(--primary-color);
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgb(236, 236, 236), -3px -3px 5px rgb(236, 236, 236);
  display: flex;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
}

.day-selector .month {
  font-weight: 700;
  font-size: 20px;
}
