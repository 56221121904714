:root {
  --text-color: #212121;
  --primary-color: #03c3ff;
}

* {
  padding: 0px;
  margin: 0px;
  font-family: Gilroy, sans-serif;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.main-container {
  height: 100vh;
  min-width: 360px;
}

.container {
  width: 100%;
  margin: auto;
}

.btn {
  color: white;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
}

.btn:hover {
  background-color: #03b4ec;
}

.btn-disabled {
  background-color: #f2f2f2;
  color: #c6ccce;
  cursor: default;
}

.btn-disabled:hover {
  background-color: #f2f2f2;
  color: #c6ccce;
}

.text-muted {
  color: #8f8f8f;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ml-16 {
  margin-left: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}
