.vehicle-type-wrapper {
  border: 1px solid #d1e0e4;
  height: 72px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 16px;
  min-width: 320px;
  cursor: pointer;
}

.vehicle-type-wrapper:hover {
  background-color: #eff6f8;
}

.vehicle-type-wrapper-active {
  border: 1px solid var(--primary-color);
  background-color: #eff6f8;
  box-shadow: 3px 3px 5px gainsboro;
}

.vehicle-type-column {
  border-right: 1px solid #d1e0e4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

.column-check {
  width: 88px;
}

.column-car {
  flex: 1;
  min-width: 150px;
  align-items: center;
  padding-left: 10px;
}

.column-ammount {
  width: 140px;
  min-width: 90px;
}

.column-price {
  width: 108px;
  min-width: 100px;
  border-right: none;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  padding-right: 10px;
}

.vehicle-type-check {
  border: solid 1px #d1e0e4;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  min-width: 24px;
  transition: border-color 0.2s ease-in-out;
}

.vehicle-type-cheked {
  border-color: var(--primary-color);
  background-image: url('../Shared/assets/img/checkmark.png');
  background-repeat: no-repeat;
  background-position: center;
}

.vehicle-type-name {
  font-size: 15px;
  font-weight: 500;
  margin-left: 16px;
  margin-right: 32px;
}

@media only screen and (max-width: 1300px) {
  .column-check {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .column-car {
    flex-direction: column;
  }

  .vehicle-type-wrapper {
    height: 120px;
  }
}
