.select-form-group {
  border: 1px solid #d1e0e4;
  background-color: #ffffff;
  border-radius: 10px;
  height: 60px;
  padding: 11px 15px 0 16px;
}

.select-noborder {
  border: 1px solid transparent;
}

.select-element {
  width: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
  margin-left: -4px;
  background-color: #ffffff;
}

.select-label-element {
  display: block;
  font-weight: 700;
  font-size: 14px;
}

.select-element:invalid {
  color: #828482;
}

.select-element option:first-child {
  color: #828482;
}

.select-element:invalid option:not(:first-child) {
  color: var(--text-color);
}

.input-box-active {
  border: 1px solid var(--primary-color);
}

.invalid-select {
  border: 1px solid red;
}

@media only screen and (max-width: 820px) {
  .select-form-group {
    border: 1px solid #d1e0e4;
    background-color: #ffffff;
    border-radius: 10px;
    height: 65px;
    margin-bottom: 20px;
  }

  .invalid-select {
    border: 1px solid red;
  }

  .select-element {
    width: 100%;
    border: none;
    font-size: 16px;
    outline: none;
    margin-top: 2px;
    margin-left: -4px;
    background-color: #ffffff;
  }
}

@media only screen and (max-width: 560px) {
  .select-form-group {
    border: 1px solid #d1e0e4;
    background-color: #ffffff;
    border-radius: 10px;
    height: 65px;
    margin-bottom: 20px;
    position:relative;
  }

  .invalid-select {
    border: 1px solid red;
  }

  .select-element {
    width: 100%;
    border: none;
    font-size: 16px;
    outline: none;
    margin-top: 2px;
    margin-left: -4px;
    background-color: #ffffff;
  }
}