.authorization-title {
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.authorization-desc {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 32px;
  color: #8f8f8f;
}

.phone-number-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.phone-country {
  background-color: #eff6f8;
  border-radius: 5px;
  height: 48px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  margin-right: 16px;
}

.phone-flag {
  height: 20px;
  width: 31px;
  margin-right: 8px;
}

.phone-input {
  outline: none;
  border-radius: 5px;
  border: 1px solid #d1e0e4;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
}

.btn-next {
  width: 126px;
  height: 60px;
  margin-bottom: 18px;
}

.digit-input {
  outline: none;
  border-radius: 5px;
  border: 1px solid #d1e0e4;
  padding: 0 12px;
  width: 45px;
  height: 48px;
  font-size: 19px;
  font-weight: 700;
  margin-right: 8px;
  text-align: center;
}

.digit-input-active {
  border: 1px solid var(--primary-color);
}

.authorization-error {
  color: red;
}

.register-note {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 32px;
}
