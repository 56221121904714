.option-button {
  color: var(--text-color);
  background-color: #eff6f8;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
  padding: 15px;
  text-align: center;
}

.option-button:hover {
  opacity: 0.7;
}

.selected {
  background-color: var(--primary-color);
  color: #ffffff;
}
