.home-map {
  background: url("../Shared/assets/img/map-home.jpg");
  height: 100%;
  width: 100%;
}

.welcome {
  font-weight: 700;
  max-width: 584px;
  margin: auto;
  margin-top: 86px;
  text-align: center;
}

.comment {
  width: fit-content;
  margin: auto;
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  color: #8f8f8f;
  text-align: center;
}

.extraDirty {
  font-weight: bold;
  width: fit-content;
  margin: auto;
  margin-top: 16px;

  font-size: 16px;
  color: #06c3fe;
  text-align: center;
}

.service-selector {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  user-select: none;
}

.service-select {
  margin-left: 12px;
  display: inline;
  user-select: none;
}

.btn-book {
  margin-left: 12px;
  width: 126px;
  user-select: none;
}

.services {
  margin-top: 92px;
}

.section-title {
  font-weight: 700;
  font-size: 48px;
}

.services-wrapper {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 214px));
  justify-content: space-between;
  margin-top: 12px;
}

.service-img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  cursor: pointer;
}

.services figcaption {
  width: auto;
  font-weight: 700;
  font-size: 20px;
  margin-top: 12px;
  height: auto;
}

.how-it-works {
  height: 362px;
  background-color: #f2f2f2;
  margin-top: 50px;
  padding: 40px 0;
}

.how-it-works .options {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.how-it-works article {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.how-it-works article p {
  margin-left: 24px;
  color: #8f8f8f;
  font-size: 20px;
}

.mobile {
  background-color: var(--primary-color);
  height: 680px;
}

.mobile .wrapper {
  display: flex;
  justify-content: space-between;
}

.mobile .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile .right {
  padding-top: 70px;
}

.mobile .title {
  font-weight: 700;
  font-size: 48px;
  color: #e8fbef;
}

.mobile .description {
  margin-top: 24px;
  color: #e8fbef;
  font-size: 20px;
}

.stores-wrapper img {
  margin-right: 32px;
  margin-top: 40px;
  user-select: none;
}

@media only screen and (max-width: 820px) {
  .how-it-works .options {
    flex-direction: column;
  }

  .how-it-works {
    height: auto;
  }

  .how-it-works article {
    padding-top: 10px;
  }

  .mobile {
    height: auto;
    padding-top: 40px;
  }

  .mobile .wrapper {
    flex-direction: column;
  }

  .mobile .right img {
    width: 100%;
    height: auto;
  }

  .services-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .welcome {
    font-weight: 700;
    font-size: 36px;
    max-width: 584px;
    margin: auto;
    margin-top: 126px;
    text-align: center;
  }

  .service-selector {
    flex-direction: column;
    user-select: none;
    width: 100%;
    align-items: center;
  }

  .service-select {
    margin-top: 12;
    display: inline;
    user-select: none;
  }

  .btn-book {
    width: 280px;
    height: 50px;
    user-select: none;
  }
}

@media only screen and (max-width: 560px) {
  .services {
    margin-top: 100px;
  }

  .welcome {
    font-weight: 700;
    font-size: 30px;
    max-width: 584px;
    margin: auto;
    margin-top: 126px;
    text-align: center;
  }

  .service-selector {
    flex-direction: column;
    user-select: none;
    width: 100%;
    align-items: center;
  }

  .service-select {
    margin-top: 12;
    display: inline;
    user-select: none;
  }

  .btn-book {
    width: 280px;
    height: 50px;
    user-select: none;
  }
}

.package-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.package {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  background-color: #ffffff;
  border-radius: 5%;
  margin-top: 15px;
  width: 280px;
  margin-right: 40px;
}

.smallPackage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  background-color: #ffffff;
  border-radius: 5%;
  margin-top: 15px;
  width: 280px;
  margin-right: 40px;
}

.package-title {
  display: flex;
  flex-direction: row;
}

.package-most-popular {
  margin-top: -15px;
  position: absolute;
  background-color: #06c3fe;
  border-radius: 50px;
  width: 60px;
  font-size: 10px;
  text-align: center;
  color: white;
}

.package-title-price {
  margin-left: auto;
  margin-right: 10px;
  color: green;
  font-weight: 500;
}

.package-content {
  padding-left: 10px;
  padding-top: 20px;
}

.package-content-details {
  list-style-type: circle;
  font-size: 13px;
  padding-left: 15px;
}

.package-button {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 10px;
}
