.check-form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d1e0e4;
  background-color: #ffffff;
  border-radius: 10px;
  height: 60px;
  padding: 0 20px;
}

.circle-check {
  border: solid 1px #d1e0e4;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  min-width: 24px;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
}

.circle-check:hover {
  border-color: var(--primary-color);
}

.circle-check-cheked {
  border-color: var(--primary-color);
  background-image: url('./assets/img/checkmark.png');
  background-repeat: no-repeat;
  background-position: center;
}

.circle-option-checked {
  border-color: var(--primary-color);
  background-image: url('./assets/img/elipse.png');
  background-repeat: no-repeat;
  background-position: center;
}

.check-element {
  width: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
}

.check-label-element {
  display: block;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.input-box-active {
  border: 1px solid var(--primary-color);
}
