.header {
  height: 155px;
}

.map-back {
  background: url('../Shared/assets/img/map-order.jpg');
  background-repeat: repeat-x;
  height: 100px;
}

.google-map {
  width: 30vw;
  height: 80vh; 
}

.header .bread-cumb {
  font-size: 16px;
  margin-top: 10px;
}

.header .page-title {
  margin-top: 48px;
  font-size: 48px;
  font-weight: 700;
}

.summary-subheader-text {
  font-size: 16;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 0px;
}

.summary-text {
  margin-top: 2px;
  margin-bottom: 5px;
}


.car-wash-note {
  outline: none !important;
    border:1px solid #03C3FF;

}

.order-form {
  margin-top: 80px;
}

.group-selector {
  margin-bottom: 48px;
}

.group-description {
  margin-top: -8px;
  margin-bottom: 24px;
}

.group-label {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 16px;
  display: block;
}

.form-control {
  margin-bottom: 16px;
}

.aditional-services-wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  justify-content: 'space-between';
  max-width: 40px;
}

.order-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.column-left {
  width: 54%;
  padding-right: 67px;
}

.column-right {
  width: 46%;
  padding-left: 68px;
}

.total-wrapper {
  border-top: 1px solid #d1e0e4;
  padding: 48px 0;
}

.total-wrapper .service-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.total-wrapper .service {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.total-wrapper .service-price {
  font-size: 16px;
  font-weight: 400;
}

.total-wrapper .grand-total-row {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  margin-bottom: 48px;
}

.total-wrapper .grand-total {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.total-wrapper .grand-total-sum {
  font-size: 24px;
  font-weight: 400;
}

.btn-order-book {
  height: 60px;
  width: 126px;
}

.calendar-disabled {
  filter: blur(3px);
  pointer-events: none;
}

.error-msg {
  color: red;
  margin-top: 24px;
}

.calendar-label-wrapper {
  display: flex;
  justify-content: space-between;
}

.login-link {
  color: var(--primary-color);
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .order-row {
    flex-direction: column;
  }

  .google-map {
    width: 80vw;
    height: 40vh; 
    margin-bottom: 5;
  }

  .column-left {
    padding-right: 0;
  }

  .column-right {
    padding-left: 0;
    margin-top: 48px;
  }
}

.button-car-wash-type {
  width: 120px;
  font-size: 13px;
  color: black;
  margin: 4px;

}
