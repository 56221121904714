.input-form-group {
  border: 1px solid #d1e0e4;
  background-color: white;
  border-radius: 10px;
  height: 60px;
  padding: 11px 15px 0 16px;
}

.noborder {
  border: 1px solid transparent;
}

.input-element {
  width: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  margin-top: 2px;
}

.label-element {
  display: block;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}

.input-box-active {
  border: 1px solid var(--primary-color);
}
