.day-button {
  color: var(--text-color);
  background-color: #eff6f8;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 0 15px;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
  padding: 15px 15px 10px 15px;
  text-align: center;
  width: 60px;
  margin-right: 10px;
}

.week-day {
  font-weight: 500;
  margin-bottom: 3px;
  font-size: 14px;
  text-transform: uppercase;
}

.month-day {
  font-size: 19px;
}

.day-button:hover {
  opacity: 0.7;
}

.day-selected {
  background-color: var(--primary-color);
  color: #ffffff;
}

.day-button-disabled {
  cursor: default;
  color: gainsboro;
}
