.updown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.down {
  width: 24px;
  height: 24px;
  background-image: url('./assets/img/down-sign.png');
  margin-right: -12px;
  z-index: 1;
  cursor: pointer;
}

.up {
  width: 24px;
  height: 24px;
  background-image: url('./assets/img/up-sign.png');
  margin-left: -12px;
  cursor: pointer;
}

.updown-input {
  outline: none;
  border: 1px solid #d1e0e4;
  border-radius: 5px;
  text-align: center;
  height: 42px;
  width: 52px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 12px;
}

.updown-active {
  border: 1px solid var(--primary-color);
}
