.navigation {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  z-index: 1;
  min-width: 120px;
  right: 0;
  margin-top: 8px;
  display: block;
}

.dropdown-item {
  padding: 12px 15px;
  display: block;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #eff6f8;
}

@media only screen and (max-width: 870px) {
  .nav-bars {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
  }

  .nav-menu {
    display: none;
    background: #D3D3D3;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-dropdown {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-dropdown .navitem {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .dropdown-menu {
    position: relative;
  }
}
